/* Loader.css */
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: white;
}

.loader__icon {
    width: 250px;
    animation: scale 2s linear infinite;
}

@keyframes scale {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}
