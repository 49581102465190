html {
    scroll-behavior: smooth;
}
.ul-li {
    list-style-type: none !important;
}
.fw-bold {
    font-weight: 500 !important;
}
.fs-5 {
    font-size: 1.1rem !important;
}
.featuresImgRow {
    margin-top: 15% !important;
    margin-right: 2% !important;
}
.panel.flip .front {
    z-index: 900;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}
.panel.flip .back {
    z-index: 1000;
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    -moz-transform: rotateX(0deg) rotateY(0deg);
}

.col-pos {
    .section-name {
        margin-bottom: -5px;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 15px;
        color: #8fc855;
    }
}
.section-name-services {
    margin-bottom: -5px;
    display: block;
    text-transform: uppercase;
    font-size: 15px;
    color: #8fc855;
    font-weight: 500;
}
.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.none-grayscale:hover {
    .grayscale {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
}
.grayscale-service {
    #img-services {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
}

.grayscale-service:hover {
    #img-services {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
}
